// eslint-disable-next-line no-unused-vars
import React, { useEffect, useRef, useState } from "react";
import { Nav, Avatar, Steps, ButtonGroup } from "@douyinfe/semi-ui";
import { IconHelpCircle } from "@douyinfe/semi-icons";
import { useDocumentTitle } from "@uidotdev/usehooks";
import styles from "./style/index.module.scss";
import { Link } from "react-router-dom";
import ShowFiles from "./showFiles";
import { FileUpload } from "./fileUpload";
import ImportFileModal from "./importFileModal";

const Layout = () => {
  useDocumentTitle("文件 - Kiwi PDF Reader");
  const [count, setCount] = useState(0);

  const incrementCount = () => {
    setCount((prevCount) => prevCount + 1);
  };
  return (
    <div className={styles.rootSidenavTab}>
      <Nav
        mode="horizontal"
        header={{
          logo: (
            <div className={styles.navigationHeaderLogo}>
              <Link
                to="https://kiwi.moe"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                🥝
              </Link>
            </div>
          ),
          text: "Kiwi PDF",
        }}
        footer={
          <div className={styles.dIV}>
            <IconHelpCircle
              size="large"
              className={styles.semiIconsHelpCircle}
            />
            <Avatar
              size="small"
              src="/assets/neko.jpg"
              color="blue"
              className={styles.avatar}
            >
              我
            </Avatar>
          </div>
        }
        className={styles.nav}
      >
        <Link
          to="https://github.com/TennousuAthena/kiwiPDF?tab=readme-ov-file#kiwi-pdf-reader"
          style={{ textDecoration: "none" }}
          target="_blank"
        >
          <Nav.Item itemKey="About" text="About" />
        </Link>
      </Nav>
      <div className={styles.main}>
        <div className={styles.content}>
          <Steps type="nav" current={1} size="small">
            <Steps.Step title="/" />
            <Steps.Step title="Files" />
          </Steps>
          <div className={styles.content}>
            <ButtonGroup style={{ alignContent: "right" }}>
              <FileUpload onUpdate={incrementCount} />
              <ImportFileModal onUpdate={incrementCount} />
            </ButtonGroup>
            <div className={styles.yourContent}>
              {useEffect(() => {
                <ShowFiles refresh={count} />;
              }, [count])}
              <ShowFiles />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
