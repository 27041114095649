// eslint-disable-next-line no-unused-vars
import React, { useRef, useEffect, useState } from "react";
import WebViewer from "@pdftron/pdfjs-express-viewer";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { findFileIdByNameAndPath, getFileContent } from "./utils/StorageFS";
import {
  Tag,
  Card,
  Typography,
  Divider,
  Skeleton,
  Tooltip,
  Toast,
  Notification,
  Descriptions,
  Rating,
} from "@douyinfe/semi-ui";
import { IconCopy, IconRefresh, IconLanguage } from "@douyinfe/semi-icons";
import { useDocumentTitle, useCopyToClipboard } from "@uidotdev/usehooks";
import Sidebar from "./Sidebar";
import Translator from "./utils/Translator";
import { useSettings } from "./utils/Settings";
import { textHandlers } from "./utils/textHelper";

/**
 * 计算预览行数
 * @param {string} sourceText
 * @returns {int}
 */
function calculateRows(sourceText) {
  const charsPerRow = 100;
  let numRows = Math.ceil(sourceText.length / charsPerRow) + 1;
  numRows = Math.min(numRows, 16);
  return numRows;
}

/**
 * 自动转化文本
 * @param {string} text
 * @param {*} settings autoConvert
 * @returns {string} processed text
 */
const processText = (text, settings) => {
  for (let i = 0; i < Object.keys(textHandlers).length; i++) {
    const key = Object.keys(textHandlers)[i];
    const value = textHandlers[key];
    if (settings[key].activate) {
      text = value.executor(text);
    }
  }
  return text;
};

/**
 * 将原始字典转化为展示内容
 * @param {object} object
 * @returns
 */
function processDictionary(obj) {
  const keyMappings = {
    id: "",
    word: "单词",
    sw: "",
    phonetic: "音标",
    definition: "定义",
    translation: "释义",
    pos: "",
    collins: "柯林",
    oxford: "牛津",
    tag: "考纲",
    bnc: "BNC",
    frq: "词频",
    exchange: "变换",
    detail: "Detail",
    audio: "Audio",
  };

  const tagMappings = {
    cet4: "四级",
    cet6: "六级",
    ky: "考研",
    toefl: "托福",
    gre: "GRE",
    ielts: "雅思",
    xiaoxue: "小学",
    zk: "中考",
    gk: "高考",
  };
  const resultArray = [];

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const humanReadableKey = keyMappings[key];
      let value = obj[key];
      if (key === "word" && value) value = <strong>{value}</strong>;
      if (key === "phonetic" && value) value = `[${value}]`;
      if (key === "tag" && value)
        value = value.split(" ").map((item) => (
          <Tag key={item} style={{ margin: "1px" }}>
            {item in tagMappings ? tagMappings[item] : item}
          </Tag>
        ));
      if (key === "oxford" && value) value = <Tag>牛津三千核心词汇</Tag>;
      if (key === "collins" && value)
        value = <Rating size="small" defaultValue={value} disabled />;
      if (key === "definition" && value) {
        value = value
          .replace(/\\n/g, "\n")
          .split("\n")
          .map((item) => <li key={item}>{item}</li>);
        value = <ul>{value}</ul>;
      }
      if (key === "exchange" && value)
        value = value.split("/").map((item) => (
          <Tag key={item} style={{ margin: "1px" }}>
            {item}
          </Tag>
        ));
      if (humanReadableKey && value)
        resultArray.push({ key: humanReadableKey, value });
    }
  }

  return resultArray;
}

const PDF = () => {
  const { Text, Title } = Typography;
  const [loading, setLoading] = useState(true);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [showSidebar, setShowSidebar] = useState(false);
  const [textSelect, setTextSelect] = useState("");
  const [sourceText, setSourceText] = useState(
    "キウイ PDF リーダーは、外国語の文書を簡単に整理して読むのに役立つオープンソースの無料ブラウザアプリケーションです。"
  );
  const [transText, setTransText] = useState(
    "Kiwi PDF Translator是一款开源免费的浏览器应用，可以方便的协助你整理、阅读外语文档。"
  );
  const [dictionary, setDictionary] = useState({});

  const viewer = useRef(null);
  const location = useLocation();
  const fileName = decodeURI(location.pathname.replace("/files/", ""));
  const [settings] = useSettings();
  const navigate = useNavigate();

  const [, copyToClipboard] = useCopyToClipboard();
  useDocumentTitle(`${fileName} - Kiwi PDF 阅读器`);

  /**
   *
   * @param {string} text 待翻译文本
   */
  const translateContent = (text) => {
    const tr = new Translator("");
    setIsFetching(true);
    tr.translate(text)
      .catch((err) => {
        Notification.error({
          title: "翻译失败",
          content: err.message,
        });
        return "";
      })
      .then((text) => {
        console.log("Translate Result", text);
        setTransText(text);
        setIsFetching(false);
      });
  };

  useEffect(() => {
    if (!isMouseDown) {
      if (textSelect.trim().length > 2) {
        const processedText = processText(textSelect, settings.autoConvert);
        setSourceText(processedText);
        if (settings.translator.enabled) {
          translateContent(processedText);
        }
        if (settings.translator.dictionary) {
          const tr = new Translator();
          if ((textSelect.trim().match(/\s/g) || []).length > 5) {
            setDictionary({});
            return;
          }
          tr.lookup(processedText.trim()).then((entry) => {
            console.log("Dictionary Result", entry);
            setDictionary(entry);
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMouseDown]);

  useEffect(() => {
    findFileIdByNameAndPath(fileName).then((fileId) => {
      if (!fileId) {
        navigate("/");
        Notification.error({
          title: fileName + ":文件不存在",
        });
      }
      getFileContent(fileId).then((content) => {
        WebViewer(
          {
            path: "/pdfjsexpress",
            licenseKey: "QXFc48APTUWruhPahXe7",
          },
          viewer.current
        )
          .then((instance) => {
            const { Core } = instance;
            instance.UI.loadDocument(new Blob([content]));
            if (settings.advanced.DarkTheme) {
              instance.UI.setTheme(instance.UI.Theme.DARK);
            }
            instance.UI.setLanguage("zh_cn");
            instance.UI.disableElements(["themeChangeButton"]);
            Core.documentViewer.addEventListener("documentLoaded", () => {
              setLoading(false);
              setIsFetching(false);
            });

            Core.documentViewer.addEventListener(
              "textSelected",
              (quads, text) => {
                setTextSelect(text);
              }
            );

            Core.documentViewer.addEventListener("mouseLeftDown", () => {
              setIsMouseDown(true);
            });
            Core.documentViewer.addEventListener("mouseLeftUp", () => {
              setIsMouseDown(false);
            });
          })
          .catch((err) => {
            if (
              err.message ===
              "Two instances of WebViewer were created on the same HTML element. Please create a new element for each instance of WebViewer"
            )
              return;
            Notification.error({
              title: "加载失败",
              content: err.message,
            });
          });
      });
    });
  }, []);

  return (
    <>
      <Sidebar visible={showSidebar} setVisible={setShowSidebar} />
      <div className="pdf-container">
        <Skeleton
          active
          placeholder={
            <>
              <Skeleton.Title
                style={{
                  width: "24em",
                  marginTop: 10,
                  marginBottom: "1em",
                }}
              />
              <Skeleton.Paragraph rows={6} />
            </>
          }
          loading={loading}
          style={{
            flexDirection: "column",
            alignItems: "center",
            marginTop: "1.5em",
            marginLeft: "2em",
            marginRight: "5em",
          }}
        ></Skeleton>
        <div
          className="pdf-component"
          style={{ opacity: loading ? "0%" : "100%" }}
        >
          <div className="webviewer" ref={viewer}></div>
        </div>
        <div className="pdf-translator">
          <Card
            title={
              <Title heading={4}>
                <Link to="/" style={{ textDecoration: "none" }}>
                  🥝
                </Link>
                Translator
              </Title>
            }
            headerExtraContent={
              <Text link onClick={() => setShowSidebar(true)}>
                设置
              </Text>
            }
          >
            {settings.translator.dictionary &&
              dictionary &&
              Object.keys(dictionary).length !== 0 && (
                <>
                  <Title heading={5}>字典</Title>
                  <Text>
                    <Descriptions
                      data={processDictionary(dictionary)}
                      style={{
                        width: "100%",
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                        overflow: "auto",
                      }}
                    />
                  </Text>
                  <Divider margin="1em">📖</Divider>
                </>
              )}
            <Title heading={5}>
              译文
              <Tooltip content={"复制到剪贴板"}>
                <IconCopy
                  onClick={() => {
                    copyToClipboard(transText);
                    Toast.success({
                      content: "复制成功",
                    });
                  }}
                  size="small"
                  style={{
                    color: "var(--semi-color-link)",
                    marginLeft: "0.5em",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
              <Tooltip content={"重新翻译"}>
                <IconRefresh
                  onClick={() => {
                    translateContent(sourceText);
                    Toast.info({
                      content: "正在翻译..",
                    });
                  }}
                  size="small"
                  style={{
                    color: "var(--semi-color-link)",
                    marginLeft: "0.5em",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </Title>
            <Skeleton
              active
              loading={isFetching}
              placeholder={
                <Skeleton.Paragraph
                  style={{ borderTop: "1em" }}
                  rows={calculateRows(sourceText)}
                />
              }
            >
              <Text style={{ whiteSpace: "pre-wrap" }}>{transText}</Text>
            </Skeleton>
            <Divider margin="1em">
              <IconLanguage />
            </Divider>
            <Title heading={5}>
              原文
              <Tooltip content={"复制到剪贴板"}>
                <IconCopy
                  onClick={() => {
                    copyToClipboard(sourceText);
                    Toast.success({
                      content: "复制成功",
                    });
                  }}
                  size="small"
                  style={{
                    color: "var(--semi-color-link)",
                    marginLeft: "0.5em",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </Title>
            <Text style={{ whiteSpace: "pre-wrap" }}>{sourceText}</Text>
          </Card>
        </div>
      </div>
    </>
  );
};

export default PDF;
