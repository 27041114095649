// importFile.ts
import { storeFile } from "./StorageFS";
import { Notification } from "@douyinfe/semi-ui";

function getRandomColor() {
  const colors = [
    "amber",
    "blue",
    "cyan",
    "green",
    "grey",
    "indigo",
    "light-blue",
    "light-green",
    "lime",
    "orange",
    "pink",
    "purple",
    "red",
    "teal",
    "violet",
    "yellow",
  ];
  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
}

function formatFileSize(fileSizeInBytes) {
  if (fileSizeInBytes >= 1024 * 1024) {
    return `${(fileSizeInBytes / (1024 * 1024)).toFixed(2)} MB`;
  } else if (fileSizeInBytes >= 1024) {
    return `${(fileSizeInBytes / 1024).toFixed(2)} KB`;
  } else {
    return `${fileSizeInBytes} bytes`;
  }
}

function calculateMetadata(file) {
  Date.prototype.format = function (fmt) {
    var o = {
      "M+": this.getMonth() + 1, //月份
      "d+": this.getDate(), //日
      "h+": this.getHours(), //小时
      "m+": this.getMinutes(), //分
      "s+": this.getSeconds(), //秒
      "q+": Math.floor((this.getMonth() + 3) / 3), //季度
      S: this.getMilliseconds(), //毫秒
    };
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        (this.getFullYear() + "").substr(4 - RegExp.$1.length)
      );
    }
    for (var k in o) {
      if (new RegExp("(" + k + ")").test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length == 1
            ? o[k]
            : ("00" + o[k]).substr(("" + o[k]).length)
        );
      }
    }
    return fmt;
  };
  return {
    size: formatFileSize(file.size),
    fileName: file.name,
    date: new Date().format("yyyy-MM-dd hh:mm:ss"),
    color: getRandomColor(),
    path: "/",
  };
}

export async function handleFileChange(event, onUpdate, doJump) {
  const files = event.target.files;
  if (files.length) {
    try {
      for (const file of files) {
        console.log(file);
        const metadata = calculateMetadata(file);
        const id = await storeFile(file, metadata); // 确保storeFile函数接受metadata参数
        console.log("文件存储成功，ID:", id);
        if (doJump) {
          window.location.href = `/files/${file.name}`;
          return;
        }
        onUpdate();
        Notification.success({
          title: "文件上传成功",
        });
      }
      // 清除文件输入以便下次上传相同文件
      event.target.value = "";
    } catch (err) {
      console.error("文件存储失败:", err);
      Notification.error({
        title: "文件上传失败",
        content: err,
      });
      event.target.value = "";
    }
  }
}

export function handleFile(onUpdate) {
  if ("launchQueue" in window) {
    window.launchQueue.setConsumer(async (launchParams) => {
      console.log(launchParams);
      handleFileChange(
        {
          target: { files: [await launchParams.files[0].getFile()] },
        },
        onUpdate,
        true
      );
    });
  }
}
