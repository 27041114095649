import { pipeline, env } from "@xenova/transformers";

export default async function clientTranslate(sourceText: string) {
  env.allowRemoteModels = false;
  env.backends.onnx.wasm.wasmPaths =
    "https://lfs.kiwi.moe/npm/@xenova/transformers@2.12.1/dist/";

  env.localModelPath = "https://lfs.kiwi.moe/models/";

  let pipe = await pipeline("translation", "Helsinki-NLP/opus-mt-en-zh");

  let out = await pipe(sourceText);
  console.log(out[0].translation_text);
  return out[0].translation_text;
}
