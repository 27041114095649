// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
import {
  SideSheet,
  Form,
  Tooltip,
  Button,
  ButtonGroup,
  Tabs,
  TabPane,
  Typography,
  Divider,
  Notification,
  Popconfirm,
  Badge,
  Tag,
} from "@douyinfe/semi-ui";
import { IconHelpCircle, IconLoading } from "@douyinfe/semi-icons";
import { Link } from "react-router-dom";
import { useSettings, resetSettings, defaultSettings } from "./utils/Settings";
import languageList from "./utils/languages";
import clientTranslate from "./utils/clientTranslate";
import { useLocalStorage } from "@uidotdev/usehooks";

const Sidebar = (state) => {
  const { Option } = Form.Select;
  const { Switch } = Form;
  const { Text } = Typography;

  const [settings, setSettings] = useSettings();
  const [formValues, setFormValues] = useState(settings);
  const [downloading, setDownloading] = useState(false);
  const [modelStatus, setModelStatus] = useLocalStorage("modelStatus", "false");

  const AutoConvert = () => {
    let element = [];
    for (let i = 0; i < Object.keys(settings.autoConvert).length; i++) {
      const key = Object.keys(settings.autoConvert)[i];
      const value = settings.autoConvert[key];
      element.push(
        <Switch
          key={key}
          field={`autoConvert.${key}.activate`}
          label={{ text: `${value.description}` }}
        ></Switch>
      );
    }
    return element;
  };

  return (
    <SideSheet
      title="阅读器设置"
      visible={state.visible}
      onCancel={() => {
        state.setVisible(!state.visible);
      }}
      className="settings-side"
    >
      <Form
        layout="vertical"
        initValues={formValues}
        onSubmit={(value) => {
          setFormValues(value);
          if (settings.advanced.DarkTheme !== value.advanced.DarkTheme) {
            window.location.reload();
          }
          setSettings(value);
          console.log(value);
          state.setVisible(!state.visible);
          Notification.success({
            title: "设置已保存",
          });
        }}
      >
        <Tabs type="line">
          <TabPane tab="翻译" itemKey="1">
            <Switch
              field="translator.enabled"
              label={{
                text: (
                  <>
                    自动翻译 <IconHelpCircle size="small" />
                  </>
                ),
              }}
            ></Switch>

            <Switch
              field="translator.dictionary"
              label={{
                text: (
                  <>
                    Kiwi查词
                    <Badge count="Beta" theme="light" />
                  </>
                ),
              }}
            />

            <Form.Select
              placeholder="Kiwi Translator"
              field="translator.service"
              label={
                <>
                  翻译引擎 <Text link>高级设置</Text>
                </>
              }
              style={{ width: "100%" }}
              prefix="使用"
            >
              <Option value="kiwi-local">
                Kiwi本地翻译
                <Tag style={{ marginLeft: "2px" }} color="amber">
                  Beta
                </Tag>
              </Option>
              <Option value="xiaoniu">小牛翻译</Option>
              <Option value="baidu">百度翻译</Option>
              <Option value="caiyun">彩云小译</Option>
              <Option value="deepl">DeepL</Option>
              <Option value="deeplx">
                DeepLX
                <Tag style={{ marginLeft: "2px" }} color="violet">
                  Free
                </Tag>
              </Option>
              <Option value="openai">
                OpenAI
                <Tag style={{ marginLeft: "2px" }} color="red">
                  Hot
                </Tag>
              </Option>
            </Form.Select>
            <Form.Select
              placeholder="源语言"
              field="translator.sourceLang"
              label={{ text: "语言设置" }}
              style={{ width: "100%" }}
              prefix="将"
              optionList={languageList}
            ></Form.Select>
            <Form.Select
              placeholder="目标语言"
              field="translator.targetLang"
              label={{ text: "" }}
              style={{ width: "100%" }}
              prefix="翻译为"
              optionList={languageList}
            ></Form.Select>

            <Divider />
          </TabPane>
          <TabPane tab="转换" itemKey="2">
            <AutoConvert />
          </TabPane>
          <TabPane tab="更多" itemKey="3">
            <Switch
              field="advanced.DarkTheme"
              label={{ text: "暗色模式" }}
            ></Switch>
            <Form.Select
              field="advanced.LocalModel"
              label={
                <>
                  Kiwi本地翻译模型
                  {downloading && modelStatus === "false" ? (
                    <Tag
                      color="red"
                      prefixIcon={<IconLoading spin />}
                      style={{ marginLeft: "2px" }}
                    >
                      下载中
                    </Tag>
                  ) : !downloading && modelStatus == "false" ? (
                    <Tag color="blue" style={{ marginLeft: "2px" }}>
                      未下载
                    </Tag>
                  ) : (
                    <Tag color="green" style={{ marginLeft: "2px" }}>
                      已下载
                    </Tag>
                  )}
                </>
              }
              placeholder="选择翻译模型"
            >
              <Option value="opus-mt-en-zh">opus-mt-en-zh(≈261MB)</Option>
            </Form.Select>
            <Button
              onClick={() => {
                setDownloading(true);
                clientTranslate("Test").then(() => {
                  setDownloading(false);
                  setModelStatus("true");
                });
              }}
            >
              下载
            </Button>
            <Switch
              field="advanced.SendAnnomousUsageData"
              label={{ text: "加入用户体验改进计划" }}
            ></Switch>
            <Divider>
              <Text style={{ fontWeight: 200 }} type="secondary">
                <Link
                  to="https://github.com/tennousuathena/kiwipdf"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    color: "var(--semi-color-text-1)",
                  }}
                >
                  🥝 Kiwi Project
                </Link>
              </Text>
            </Divider>
          </TabPane>
        </Tabs>
        <ButtonGroup style={{ marginTop: "1em" }}>
          <Button htmlType="submit">保存</Button>
          <Popconfirm
            title="确认重置"
            content="您将失去本地所有设置"
            onConfirm={() => {
              resetSettings();
              setFormValues(defaultSettings);
              Notification.success({
                title: "已重置所有",
              });
              state.setVisible(!state.visible);
            }}
          >
            <Button type="warning">重置</Button>
          </Popconfirm>
        </ButtonGroup>
      </Form>
    </SideSheet>
  );
};

export default Sidebar;
