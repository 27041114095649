import { getSettings } from "./utils/Settings";

export default function DarkTheme() {
  const settings = getSettings();
  const body = document.body;

  if (settings.advanced.DarkTheme) {
    body.setAttribute("theme-mode", "dark");
    body.classList.add("dark");
  }
}
