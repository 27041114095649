// eslint-disable-next-line no-unused-vars
import React, { useState, useRef } from "react";
import { Form, Modal, Button, Toast } from "@douyinfe/semi-ui";
import { IconImport } from "@douyinfe/semi-icons";
import { handleFileChange } from "./utils/importFile";

function ImportFileModal({ onUpdate }) {
  const [visible, setVisible] = useState(false);
  const [typeSelect, setTypeSelect] = useState("URL");
  const formApiRef = useRef(null);

  const showDialog = () => {
    setVisible(true);
  };

  const handleOk = () => {
    formApiRef.current
      .validate()
      .then((values) => {
        console.log(values);
      })
      .catch((errors) => {
        console.log(errors);
      });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const getFormApi = (formApi) => {
    formApiRef.current = formApi;
  };

  const handleSelectChange = (value) => {
    setTypeSelect(value);
  };

  const fetchArxiv = (id) => {
    const url = "https://arxiv.org/pdf/" + id + ".pdf";
    fetchURL(url);
  };

  const fetchURL = (url) => {
    if (!/^https:\/\/[^\s$.?#].[^\s]*$/.test(url)) {
      Toast.error("URL格式错误");
      return;
    }
    fetch(url)
      .then((response) => {
        if (
          response.ok &&
          response.headers.get("content-type") === "application/pdf"
        ) {
          console.log(response);
          const fileName = url.split("/").pop();
          response.blob().then((blob) => {
            const file = new File([blob], fileName);
            handleFileChange({ target: { files: [file] } }, onUpdate, false);
            Toast.success(`导入文件${fileName}成功`);
          });
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .catch((error) => {
        Toast.error("获取文档失败:" + error.message);
      });
  };

  const fetchFile = () => {
    formApiRef.current.validate().then((values) => {
      Toast.info("正在获取文档...");
      if (values.importFrom === "URL") {
        fetchURL(values.importContent);
      } else if (values.importFrom === "arxiv") {
        fetchArxiv(values.importContent);
      }
    });
  };

  return (
    <>
      <Button icon={<IconImport />} aria-label="导入" onClick={showDialog}>
        导入文档
      </Button>
      <Modal
        title="导入文档"
        visible={visible}
        onOk={handleOk}
        style={{ width: "60vw" }}
        onCancel={handleCancel}
      >
        <Form getFormApi={getFormApi}>
          <Form.InputGroup
            label={{ text: <span>文档信息</span>, required: true }}
            labelPosition="top"
          >
            <Form.Select
              style={{ width: "8em" }}
              field="importFrom"
              initValue="URL"
              rules={[{ required: true }]}
              onChange={handleSelectChange}
            >
              <Form.Select.Option value="URL">URL</Form.Select.Option>
              <Form.Select.Option value="arxiv">arXiv</Form.Select.Option>
              <Form.Select.Option value="DOI" disabled>
                DOI
              </Form.Select.Option>
            </Form.Select>
            <Form.Input
              style={{ width: 350 }}
              field="importContent"
              rules={[{ required: true }]}
              placeholder={
                typeSelect === "URL"
                  ? "https://arxiv.org/pdf/1145.141919.pdf"
                  : "1145.141919"
              }
              showClear
            />
          </Form.InputGroup>

          <Button onClick={fetchFile}>获取</Button>
        </Form>
      </Modal>
    </>
  );
}

export default ImportFileModal;
