interface LanguageOption {
  value: string;
  label: string;
}

const languageList: LanguageOption[] = [
  { value: "zh", label: "简体中文" },
  { value: "en", label: "English" },
  { value: "ja", label: "日本語" },
  { value: "ko", label: "한국어" },
  { value: "fr", label: "Français" },
  { value: "de", label: "Deutsch" },
  { value: "es", label: "Español" },
  { value: "pt", label: "Português" },
  { value: "it", label: "Italiano" },
  { value: "ru", label: "Русский" },
  { value: "vi", label: "Tiếng Việt" },
  { value: "th", label: "ภาษาไทย" },
  { value: "ar", label: "العربية" },
  { value: "hi", label: "हिन्दी" },
  { value: "bn", label: "বাংলা" },
  { value: "tr", label: "Türkçe" },
  { value: "pl", label: "Polski" },
  { value: "nl", label: "Nederlands" },
  { value: "id", label: "Bahasa Indonesia" },
  { value: "ms", label: "Bahasa Melayu" },
  { value: "fa", label: "فارسی" },
  { value: "sv", label: "Svenska" },
  { value: "he", label: "עברית" },
  { value: "el", label: "Ελληνικά" },
  { value: "da", label: "Dansk" },
  { value: "fi", label: "Suomi" },
  { value: "no", label: "Norsk" },
  { value: "cs", label: "Čeština" },
  { value: "hu", label: "Magyar" },
  { value: "ro", label: "Română" },
  { value: "sk", label: "Slovenčina" },
  { value: "sl", label: "Slovenščina" },
  { value: "bg", label: "Български" },
  { value: "uk", label: "Українська" },
  { value: "sr", label: "Српски" },
  { value: "hr", label: "Hrvatski" },
  { value: "lt", label: "Lietuvių" },
  { value: "lv", label: "Latviešu" },
  { value: "et", label: "Eesti" },
  { value: "mt", label: "Malti" },
];

export default languageList;
