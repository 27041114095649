import React from "react";
import ReactDOM from "react-dom/client";
import Layout from "./layout.jsx";
import PDF from "./PDF.jsx";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import RouteNotFound from "./pages/404.jsx";
import DarkTheme from "./darkTheme";
import ErrorBoundary from "./ErrorBoundary.jsx";

import "reset.css";
import "./style/index.css";

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/sw.js")
    .then(function (registration) {
      console.log("Service Worker 注册成功:", registration);
    })
    .catch(function (error) {
      console.log("Service Worker 注册失败:", error);
    });
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <ErrorBoundary>
      <DarkTheme />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />} />
          <Route path="/files/*" element={<PDF />} />
          <Route path="*" element={<RouteNotFound />} />
        </Routes>
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>
);
