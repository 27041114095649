import { openDB } from "idb";

/**
 * 初始化用于存储文件的IndexedDB数据库
 */
async function initDB() {
  const db = await openDB("kiwiPDF-database", 1, {
    upgrade(db) {
      // 创建一个名为'files'的对象存储
      if (!db.objectStoreNames.contains("files")) {
        db.createObjectStore("files", { keyPath: "id", autoIncrement: true });
      }
      // 创建一个名为'file-meta'的对象存储
      if (!db.objectStoreNames.contains("file-meta")) {
        db.createObjectStore("file-meta", {
          keyPath: "id",
          autoIncrement: true,
        });
      }
    },
  });
  return db;
}

async function storeFile(file, metadata) {
  initDB();
  const db = await openDB("kiwiPDF-database", 1);

  // 检查文件是否已经存在
  const fileId = await findFileIdByNameAndPath(
    metadata.fileName,
    metadata.path
  );
  if (fileId) {
    return fileId;
  }

  // 将文件转换为二进制格式
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async () => {
      try {
        // 开启一个事务，以便同时写入文件和元数据
        const tx = db.transaction(["files", "file-meta"], "readwrite");
        // 将文件内容存储在IndexedDB中
        const fileStore = tx.objectStore("files");
        const fileID = await fileStore.add({ content: reader.result });
        // 将文件元数据存储在IndexedDB中
        const metaStore = tx.objectStore("file-meta");
        await metaStore.add({ ...metadata, fileId: fileID });

        // 等待事务完成
        await tx.done;

        resolve(fileID);
      } catch (err) {
        reject(err);
      }
    };
    reader.onerror = () => {
      reject(reader.error);
    };

    reader.readAsArrayBuffer(file);
  });
}

async function getAllFileMetadata() {
  initDB();
  const db = await openDB("kiwiPDF-database", 1);
  const tx = db.transaction("file-meta", "readonly");
  const store = tx.objectStore("file-meta");
  const allMetadata = await store.getAll();
  await tx.done;
  db.close();
  return allMetadata;
}

async function deleteFile(fileId) {
  console.log("deleting file", fileId);
  const db = await openDB("kiwiPDF-database", 1);
  const tx = db.transaction(["files", "file-meta"], "readwrite");
  const filesStore = tx.objectStore("files");
  const metaStore = tx.objectStore("file-meta");

  await filesStore.delete(fileId);
  const allMeta = await metaStore.getAll();
  const metaToDelete = allMeta.find((meta) => meta.fileId === fileId);
  if (metaToDelete) {
    await metaStore.delete(metaToDelete.id);
  }

  await tx.done;
  db.close();
  return fileId;
}

async function getFileContent(fileId) {
  const db = await openDB("kiwiPDF-database", 1);
  const tx = db.transaction("files", "readonly");
  const store = tx.objectStore("files");
  const fileRecord = await store.get(fileId);
  await tx.done;
  db.close();

  if (fileRecord) {
    return fileRecord.content;
  } else {
    throw new Error("文件未找到");
  }
}

async function findFileIdByNameAndPath(fileName, path = "/") {
  const db = await openDB("kiwiPDF-database", 1);
  const tx = db.transaction("file-meta", "readonly");
  const store = tx.objectStore("file-meta");
  let fileId = null;

  // 使用游标遍历所有记录
  try {
    const cursor = await store.openCursor();
    while (cursor) {
      if (cursor.value.fileName === fileName && cursor.value.path === path) {
        fileId = cursor.value.fileId; // 假设元数据中包含文件ID
        break;
      }
      await cursor.continue();
    }

    await tx.done;
    db.close();
    return fileId;
  } catch (error) {
    return "";
  }
}

export {
  storeFile,
  getAllFileMetadata,
  deleteFile,
  getFileContent,
  findFileIdByNameAndPath,
};
